import Vue from 'vue'
import Vuex from 'vuex'
import { host } from '@/utils/config.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // fileUrl: 'https://por-files.q-edu.net/'
    // fileUrl: 'http://192.168.98.53:17072/'
    fileUrl: '/' // 配置group代理
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
